
/** 遍历数组，扩充数组 */
export const alignArrays = (chooseArray, realArray) => {
    // 创建一个新的数组来存储对齐后的结果
    const alignedArray = [];

    // 遍历 chooseArray
    for (const chooseItem of chooseArray) {
        const key = chooseItem.key;

        // 在 realArray 中查找与 chooseItem.key 相匹配的项
        const realItem = realArray.find(item => item.key === key);

        // 如果找到了匹配的项，则将其添加到 alignedArray 中
        if (realItem) {
            alignedArray.push(realItem);
        } else {
            // 如果没有找到匹配的项，则添加一个填充项
            alignedArray.push({ key: undefined, desc: undefined });
        }
    }

    return alignedArray;
}