import instance from "@/axios"

/** 获取faq列表数据API */
export const getStudyListDataApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestion/list',
        method: 'GET',
        params: params
    })
}
/** 新增内容-faq内容 */
export const postAddStudyContentApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestion/add',
        method: 'post',
        data: params
    })
}
/** 编辑内容-faq内容 */
export const postEditStudyContentApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestion/edit',
        method: 'post',
        data: params
    })
}
/** 删除内容-faq内容 */
export const postDelStudyContentApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestion/delById',
        method: 'get',
        params: params
    })
}