<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  标题    -->
      <div slot="itemStudyTitleSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.title }}</div>
      </div>
<!--  状态    -->
      <div slot="itemStudyStatusSlot" slot-scope="row">
        <div class="font-weight-bold" :class="pushStatusTypeListTypeClassType[row.status]">{{ computedPushStatusTypeListMapText(row.status) }}</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button type="primary" @click="handleEditor(row)">编辑</a-button>
        <div class="mt-5">
          <a-popconfirm title="确认删除当前问题？" @confirm="handleDelete(row)">
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑  -->
    <EditorOpenShopStudyQuestionPopup ref="editorOpenShopStudyQuestionPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {openShopStudyListTableColumns} from "@/views/cmsPage/contentDataMange/openShopStudyManage/_data"
import EditorOpenShopStudyQuestionPopup
  from "@/views/cmsPage/contentDataMange/openShopStudyManage/_components/EditorOpenShopStudyQuestionPopup/index.vue"
import {postDelStudyContentApi} from "@/views/cmsPage/contentDataMange/openShopStudyManage/_apis"
import {pushStatusTypeListMapText, pushStatusTypeListTypeClassType} from "@/_data"


export default {
  components: {EditorOpenShopStudyQuestionPopup},
  props: ['pagination', 'list'],
  emits: ['success', 'changePage'],
  data() {
    return {
      pushStatusTypeListTypeClassType, // 是否发布状态样式
      tableList: openShopStudyListTableColumns,
    }
  },
  computed: {
    computedPushStatusTypeListMapText() {
      return (value) => pushStatusTypeListMapText(value)
    }
  },
  methods: {
    /** 确定删除 */
    async handleDelete(row) {
      this.$loading.show()
      const res = await postDelStudyContentApi({id: row.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 编辑 */
    handleEditor(row) {
      this.$refs.editorOpenShopStudyQuestionPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>