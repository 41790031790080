var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _vm.showPopup
        ? _c(
            "a-form-model",
            _vm._b({}, "a-form-model", _vm.layout, false),
            [
              _c(
                "a-form-item",
                { attrs: { label: "问题标题" } },
                [
                  _c("a-input", {
                    staticClass: "w-100-w",
                    attrs: { allowClear: "", placeholder: "问题标题" },
                    model: {
                      value: _vm.params.title,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "title", $$v)
                      },
                      expression: "params.title"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.inputCount, function(item, index) {
                return _c(
                  "a-form-item",
                  { key: item, attrs: { label: "选项-" + item } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-start-center" },
                      [
                        _c("a-input", {
                          staticClass: "w-400",
                          attrs: { allowClear: "", placeholder: "答案选项" },
                          model: {
                            value: _vm.inputListData[index].title,
                            callback: function($$v) {
                              _vm.$set(_vm.inputListData[index], "title", $$v)
                            },
                            expression: "inputListData[index].title"
                          }
                        }),
                        _vm.inputCount === 1 || index + 1 === _vm.inputCount
                          ? _c("a-button", {
                              staticClass: "ml-20",
                              attrs: { type: "primary", icon: "plus" },
                              on: {
                                click: function($event) {
                                  return _vm.handleAddItem(item, index)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.inputCount > 1 && index + 1 === _vm.inputCount
                          ? _c("a-button", {
                              staticClass: "ml-20",
                              attrs: { type: "danger", icon: "minus" },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelItem(item, index)
                                }
                              }
                            })
                          : _vm._e(),
                        _c(
                          "a-checkbox",
                          {
                            staticClass: "ml-20",
                            attrs: {
                              checked:
                                _vm.sureListData[index] &&
                                _vm.sureListData[index].key ===
                                  _vm.inputListData[index].key
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleChangeCheckBoxItem(
                                  $event,
                                  item,
                                  index
                                )
                              }
                            }
                          },
                          [_vm._v("是否为答案")]
                        )
                      ],
                      1
                    ),
                    _vm.sureListData[index] &&
                    _vm.sureListData[index].key === _vm.inputListData[index].key
                      ? _c(
                          "div",
                          { staticClass: "flex-start-center mt-5" },
                          [
                            _c("a-textarea", {
                              staticClass: "w-400",
                              attrs: {
                                type: "text",
                                "auto-size": { minRows: 4, maxRows: 6 },
                                placeholder: "答案解析描述"
                              },
                              model: {
                                value: _vm.sureListData[index].desc,
                                callback: function($$v) {
                                  _vm.$set(_vm.sureListData[index], "desc", $$v)
                                },
                                expression: "sureListData[index].desc"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              }),
              _c(
                "a-form-item",
                { attrs: { label: "是否发货" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.params.status,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("草稿")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("发布")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }