<template>
  <a-modal v-model="showPopup" :width='800' :title="titleText" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout" v-if="showPopup">
      <a-form-item label="问题标题">
        <a-input allowClear v-model="params.title" class="w-100-w" placeholder='问题标题'></a-input>
      </a-form-item>
      <a-form-item :label="`选项-${item}`" v-for="(item, index) in inputCount" :key="item">
        <div class="flex-start-center">
          <a-input allowClear v-model="inputListData[index].title" class="w-400" placeholder='答案选项'></a-input>
          <a-button v-if="inputCount === 1 || (index + 1 === inputCount)" class="ml-20" type="primary" icon="plus" @click="handleAddItem(item, index)"></a-button>
          <a-button v-if="inputCount > 1 && (index + 1 === inputCount)" class="ml-20" type="danger" icon="minus" @click="handleDelItem(item, index)"></a-button>
          <a-checkbox class="ml-20" :checked='sureListData[index] && (sureListData[index].key === inputListData[index].key)' @change="handleChangeCheckBoxItem($event, item, index)">是否为答案</a-checkbox>
        </div>
        <div class="flex-start-center mt-5" v-if="sureListData[index] && (sureListData[index].key === inputListData[index].key)">
          <a-textarea
              type="text"
              class="w-400"
              v-model="sureListData[index].desc"
              :auto-size="{ minRows: 4, maxRows: 6 }"
              placeholder="答案解析描述"
          ></a-textarea>
        </div>
      </a-form-item>
      <a-form-item label="是否发货">
        <a-radio-group name="radioGroup" v-model="params.status">
          <a-radio :value="0">草稿</a-radio>
          <a-radio :value="1">发布</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {postAddStudyContentApi, postEditStudyContentApi} from "@/views/cmsPage/contentDataMange/openShopStudyManage/_apis"
import {emptyAttrIndex, emptyOneAttrHasTwoAttrNotIndex, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {alignArrays} from "@/views/cmsPage/contentDataMange/openShopStudyManage/_service"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      titleText: '新增问题',
      layout: {
        labelCol: { span: 3 }, //标签占5份  共24份
        wrapperCol: { span: 21 },
      },
      params: {
        id: undefined,
        title: undefined,
        status: 0,
        chooseAnswer: [],
        realAnswer: [],
      },
      isEditor: false,
      inputCount: 1,
      inputListData: [
        { title: undefined, key: 0 }
      ],
      sureListData: [
        { key: undefined, desc: undefined }
      ]
    }
  },
  methods: {
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.title) return this.$message.warning('请填写问题标题')
      if (emptyAttrIndex(this.inputListData, 'title') >= 0) return this.$message.warning(`请填写选项-${emptyAttrIndex(this.inputListData, 'title') + 1}`)
      if (emptyOneAttrHasTwoAttrNotIndex(this.sureListData, 'key', 'desc') >= 0) return this.$message.warning(`请填写选项-${emptyOneAttrHasTwoAttrNotIndex(this.sureListData, 'key', 'desc') + 1}的答案解析`)
      if (!this.sureListData.filter(el => el.key !== undefined).length) return this.$message.warning('必须有将一个选项设为正确答案')
      this.params.chooseAnswer = this.inputListData
      this.params.realAnswer = this.sureListData.filter(el => el.key !== undefined)
      this.$loading.show()
      let res
      if (this.isEditor) {
        res = await postEditStudyContentApi(this.params)
      } else {
        res = await postAddStudyContentApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 修改check-box */
    handleChangeCheckBoxItem(e, item, index) {
      console.log(e, item, index, '书名')
      if (e.target.checked) {
        this.sureListData[index].key = this.inputListData[index].key
      } else {
        this.sureListData[index].key = undefined
      }
      console.log(this.sureListData, '结果选择')
    },
    /** 增加选项 */
    handleAddItem(item, index) {
      this.inputCount++
      this.inputListData.push({ title: undefined, key: index + 1 })
      this.sureListData.push({ key: undefined, desc: undefined })
      console.log(this.inputListData, this.sureListData, '输入的内容')
    },
    /** 删除选项 */
    handleDelItem(item, index) {
      this.inputCount--
      this.inputListData.pop()
      this.sureListData.pop()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        title: undefined,
        status: 0,
        chooseAnswer: [],
        realAnswer: [],
      }
      this.inputCount = 1
      this.inputListData = [
        { title: undefined, key: 0 }
      ]
      this.sureListData = [
        { key: undefined, desc: undefined }
      ]
      this.showPopup = false
    },
    /** 展示 */
    show(data) {
      if (data) {
        this.isEditor = true
        this.setData(data)
        this.titleText = '编辑问题'
      } else {
        this.isEditor = false
        this.titleText = '新增问题'
        this.showPopup = true
      }
    },
    /** 设置默认值 */
    setData(data) {
      this.params = updateBaseObjectWithComplex(this.params, data)
      this.inputCount = data.chooseAnswer.length
      this.inputListData = data.chooseAnswer
      this.sureListData = alignArrays(data.chooseAnswer, data.realAnswer)
      this.showPopup = true
    },
  }
}
</script>